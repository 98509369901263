body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;

  background: #181b1c;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to bottom, #3296fa11, #181B1C); 
    background: linear-gradient(to bottom, #3296fa11, #181B1C);  */
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* overflow: unset !important; */
}

html {
  padding: 0;
  margin: 0;
  background: 'none';
}

sup {
  vertical-align: top;
  font-size: 0.6em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bn-onboard-custom.bn-onboard-modal {
  z-index: 99;
}

.crab-notice {
  background: linear-gradient(270deg, rgba(32, 202, 26, 0.1), rgba(19, 131, 17, 0.4));
  background-size: 200% 200%;

  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 46%;
  }

  50% {
    background-position: 100% 55%;
  }

  100% {
    background-position: 0% 46%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 46%;
  }

  50% {
    background-position: 100% 55%;
  }

  100% {
    background-position: 0% 46%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 46%;
  }

  50% {
    background-position: 100% 55%;
  }

  100% {
    background-position: 0% 46%;
  }
}

#rain-front {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#rain-back {
  z-index: 1;
  bottom: 60px;
  opacity: 0.5;
}

.drop {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: drop 0s linear infinite;
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }

  75% {
    transform: translateY(90vh);
  }

  100% {
    transform: translateY(100vh);
  }
}

.stem {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
  animation: stem 0s linear infinite;
}

@keyframes stem {
  0% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}